<template>
  <v-img
    v-if="tenantLayout.tenantLogo != undefined && !isCoto"
    :max-width="pMaxWidth"
    :src="tenantLayout.tenantLogo"
    fluid
  />
  <v-img
    v-else-if="tenantLayout.tenantLogo == undefined && !isCoto"
    :max-width="pMaxWidth"
    :src="require('@/assets/images/csi.png')"
    fluid
  />
  <v-img
    v-else
    :max-width="pMaxWidth"
    :src="require('@/assets/images/logotorinofacile.png')"
    fluid
  />
</template>

<script>
import { mapGetters } from "vuex";
import { isCotoHostname } from "@/common/utils";

export default {
  name: "TenantLogo",
  props: {
    pMaxWidth: { type: Number, required: false }
  },
  computed: {
    ...mapGetters(["tenantLayout"]),
    isCoto() {
      return isCotoHostname();
    }
  }
};
</script>
